Array.prototype.to_sentence = function() {
  return this.join(", ").replace(/,\s([^,]+)$/, " en $1");
};

window.showFailDialog = function() {
  $(
    ' \
    <div class="modal fade" tabindex="-1" role="dialog">\
    <div class="modal-dialog" role="document">\
    <div class="modal-content">\
        <div class="modal-header">\
        <h4 class="modal-title">Oeps!</h4>\
        </div>\
        <div class="modal-body">\
        Sorry, er ging iets mis bij het verwerken van de aanvraag. Probeer u het a.u.b. later nog eens of\
        neem contact met ons op via telefoon op 040-7110344 (werkdagen van 08:00 tot 17:00).\
        </div>\
    </div>\
    </div>\
</div>\
'
  )
    .appendTo(document.body)
};

$(document).on("submit", "form[data-remote='true']", function(event) {
  event.stopPropagation();
  event.preventDefault();
  let $form = $(this);
  $.ajax({
    method: $form.attr("method"),
    url: $form.attr("action"),
    data: $form.serialize(),
    dataType: "json",
    beforeSend: function(_jqXHR, _settings) {
      $("input[type=submit]", $form)
        .prop("disabled", true)
        .val("Een moment geduld a.u.b.");
    },
    complete: function(_jqXHR, _textStatus) {
      $("input[type=submit]", $form)
        .prop("disabled", false)
        .val("Verstuur");
    },
    error: function(_jqXHR, _textStatus, _errorThrown) {
      window.showFailDialog();
    },
    success: function(data, _textStatus, _jqXHR) {
      if (data.status == "failed") {
        window.showFailDialog();
      } else if (data.status == "invalid") {
        $.each($("input, textarea", $form), function() {
          var input = $(this);
          var name = input.prop("name").replace(/.*\[(.*?)\].*/, "$1");
          var form_group = input.parents(".form-group:first");
          $(".help-block", form_group).remove();
          if (name in data.errors) {
            form_group.addClass("has-error");
            $(
              "<span class='help-block'>" +
                data.errors[name].to_sentence() +
                "</span>"
            ).appendTo(form_group);
          } else if (form_group.length > 0) {
            form_group.removeClass("has-error");
          }
          if (data.captcha_feedback && data.captcha_feedback[0] == "error") {
            $("#contact-form-captcha").addClass("has-error");
            $(
              "<span class='help-block'>is niet juist ingevuld</span>"
            ).appendTo($("#contact-form-captcha"));
          } else {
            $("#contact-form-captcha").removeClass("has-error");
          }
        });
      } else {
        window.form_submitted = $($(".form_submitted", $form).html()).appendTo(
          document.body
        );
        $form.replaceWith(form_submitted);
        $("html, body").animate(
          {
            scrollTop: window.form_submitted.offset().top
          },
          1000
        );
      }
    }
  });
});
